import { wixAxiosInstanceConfig } from '@wix/wix-axios-config';
import axios from 'axios';

const PERMISSIONS_PARAMS = {
  group_id: '00000000-0000-0000-0000-000000000001',
  permissions_id: 'cce6b124-b14c-4383-aee9-5e0814811ae2',
  context: 'Pages',
};

let axiosInstance = null;
async function getPlatformizedAxiosInstance(getAppInstance) {
  if (!axiosInstance) {
    axiosInstance = wixAxiosInstanceConfig(axios, {
      baseURL: '/',
      req: { headers: { Authorization: await getAppInstance() } },
    });
  }
  return axiosInstance;
}

export const setPagePermissions = async (pageRef, editorSDK) => {
  axiosInstance = await getPlatformizedAxiosInstance(
    editorSDK.info.getAppInstance,
  );
  const data = {
    permissions: [
      {
        id: PERMISSIONS_PARAMS.permissions_id,
        context: PERMISSIONS_PARAMS.context,
        resourceIds: [pageRef.id],
      },
    ],
  };
  const url = `https://editor.wix.com/_api/members-groups-web/v1/groups/${PERMISSIONS_PARAMS.group_id}/permissions`;
  axiosInstance.post(url, data);
};
