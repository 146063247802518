const { eventNames } = require('../constants/eventNames');
const { panelDefinitions } = require('../panels/panel-definitions');
const { getBaseUrl } = require('../utils/urlBuilders');
const { Translations } = require('../utils/translations');
const {
  createPage,
  markNewDynamicPageAsAdminPage,
} = require('../utils/editorUtils');
const {
  DASHBOARD_PAGES_HELP_ID,
  PAGE_INFO_HELP_ID,
  PAGE_PERMISSIONS_PANEL_ID,
} = require('../constants/helpIds');
const { isExperimentEnabled } = require('../utils/experiments');

class AdminPagesEditorApp {
  private editorSDK;
  private appDefinitionId;
  private translation;
  private locale: string;
  private adminPagesScriptUrl: string;
  private firstPage: boolean;

  async editorReady(
    editorSDK,
    appDefinitionId,
    { firstInstall, initialAppData },
  ) {
    this.firstPage = firstInstall;
    this.editorSDK = editorSDK;
    this.appDefinitionId = appDefinitionId;
    this.locale = await editorSDK.environment.getLocale();
    this.translation = new Translations();
    this.adminPagesScriptUrl = initialAppData.editorScriptUrl;

    await this.translation.editorInit(
      this.locale,
      getBaseUrl(this.adminPagesScriptUrl),
    );
    const adminPages = await this.editorSDK.pages.getApplicationPages();
    if (!firstInstall) {
      return;
    }
    if (adminPages && adminPages.length === 0) {
      return this.openModalPanel();
    }
    return createPage(editorSDK, appDefinitionId);
  }

  getDataBindingAPI() {
    return this.editorSDK.document.application.getPublicAPI('token', {
      appDefinitionId: 'dataBinding',
    });
  }

  openModalPanel() {
    const sdkScriptSrc = this.editorSDK.info.getSdkVersion().scriptSrc;
    return this.editorSDK.editor.openModalPanel(
      'token',
      panelDefinitions.addPagePanel(sdkScriptSrc),
    );
  }

  async handleAddPage() {
    return createPage(this.editorSDK, this.appDefinitionId);
  }
  async handleAppPageIfNotFirstTime() {
    if (!this.firstPage) {
      return this.handleAddPage();
    }
    this.firstPage = false;
  }

  async handleRemovePage(pageRef) {
    const databindingApi = await this.getDataBindingAPI();
    const isDynamicPage = await databindingApi.isDynamicPage({
      pageRef,
    });
    if (isDynamicPage) {
      await databindingApi.removeDynamicPage({
        pageRef,
      });
    } else {
      await this.editorSDK.pages.remove('token', { pageRef });
    }
  }

  async handleSetAsDynamicPage(pageRef) {
    const databindingApi = await this.getDataBindingAPI();
    const pageRefs = await databindingApi.convertStaticPageToDynamic({
      pageRef,
    });
    const newPage = pageRefs.filter(page => page.id !== pageRef.id)[0];
    return markNewDynamicPageAsAdminPage(
      this.editorSDK,
      this.appDefinitionId,
      newPage,
    );
  }

  async onEvent(event) {
    switch (event.eventType) {
      case eventNames.addPage:
        return this.handleAddPage();
      case eventNames.deletePage:
        return this.handleRemovePage(event.eventPayload.pageRef);
      case eventNames.dataSectionAppClicked:
        return this.handleAppPageIfNotFirstTime();
      case eventNames.setAsADynamicPage:
        return this.handleSetAsDynamicPage(event.eventPayload.pageRef);
      default:
        break;
    }
  }

  async getDefaultPageActions() {
    const defaultActionsArr = [];
    defaultActionsArr.push('Pages_Actions_Page_Rename', {
      title: this.translation.t('page_actions.delete_page'),
      event: eventNames.deletePage,
      icon: 'delete_icon',
      type: 'remove_page',
    });
    defaultActionsArr.push({
      // TODO hide if its already a dynamic page
      title: this.translation.t('page_actions.set_as_dynamic_page'),
      event: eventNames.setAsADynamicPage,
      icon: 'page_dynamic_icon',
    });
    return defaultActionsArr;
  }

  async getAppManifest() {
    const sdkScriptSrc = this.editorSDK.info.getSdkVersion().scriptSrc;
    return {
      controllersStageData: {
        singleAdminPageController: {
          default: {
            visibility: 'NEVER',
          },
        },
      },
      pages: {
        pageActions: {
          default: await this.getDefaultPageActions(),
          overrides: [
            {
              condition: {
                advanced: false,
              },
              override: [],
            },
          ],
        },
        pageSettings: {
          default: [
            {
              title: this.translation.t('page_settings.page_info'),
              event: 'pageInfo',
              icon: 'page_dynamic_icon',
              url: panelDefinitions.pageInfoPanel(sdkScriptSrc).url,
              helpId: PAGE_INFO_HELP_ID,
              type: 'page_info',
            },
            {
              title: this.translation.t('page_settings.page_permissions'),
              event: 'pagePermissions',
              icon: 'page_dynamic_icon',
              url: panelDefinitions.permissionsPanel(sdkScriptSrc).url,
              helpId: PAGE_PERMISSIONS_PANEL_ID,
              type: 'permissions',
            },
          ],
        },
        applicationSettings: {
          default: {
            displayName: this.translation.t('dashboard-pages'),
            helpId: DASHBOARD_PAGES_HELP_ID,
            grouping: {
              groupBy: 'routers',
            },
          },
        },
        applicationActions: {
          default: [
            {
              title: this.translation.t('page_actions.add_page'),
              event: eventNames.addPage,
              icon: 'addPagePagesPanel',
              type: 'add_page',
            },
          ],
          overrides: [
            {
              condition: {
                advanced: false,
              },
              override: [],
            },
          ],
        },
      },
    };
  }
}

function autoBind(obj) {
  const bound = {};
  const props = Reflect.ownKeys(Reflect.getPrototypeOf(obj));
  props.forEach(p => {
    if (p !== 'constructor' && typeof obj[p] === 'function') {
      bound[p] = obj[p].bind(obj);
    }
  });

  return bound;
}

module.exports = autoBind(new AdminPagesEditorApp());
