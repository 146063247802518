import { setPagePermissions } from './api';

const getPageStructure = appDefinitionId => ({
  data: {
    hideTitle: false,
    isLandingPage: true,
    indexable: false,
    hidePage: true,
    managingAppDefId: appDefinitionId,
    pageSecurity: {
      requireLogin: true,
    },
  },
  components: [],
});

async function addPageToComponent(editorSDK, appDefinitionId, pageRef) {
  await editorSDK.components.add('token', {
    componentDefinition: {
      componentType: 'platform.components.AppController',
      data: {
        controllerType: 'singleAdminPageController',
        name: 'admin-pages-controller',
        applicationId: appDefinitionId,
        settings: JSON.stringify({
          pageId: pageRef.id,
        }),
      },
    },
    pageRef,
  });
}

async function updatePageDefinitions(pageRef, appDefinitionId, editorSDK) {
  editorSDK.pages.data.update('token', {
    pageRef,
    data: { managingAppDefId: appDefinitionId, isLandingPage: true },
  });
}

function openPagesPanel(editorSDK, pageRef) {
  return editorSDK.editor.openPagesPanel('token', {
    renameEnabled: true,
    pageRef,
  });
}

export async function markNewDynamicPageAsAdminPage(
  editorSDK,
  appDefinitionId,
  pageRef,
) {
  await updatePageDefinitions(pageRef, appDefinitionId, editorSDK);
  await setPagePermissions(pageRef, editorSDK);
  await addPageToComponent(editorSDK, appDefinitionId, pageRef);
  return openPagesPanel(editorSDK, pageRef);
}

export async function createPage(editorSDK, appDefinitionId) {
  await editorSDK.history.add('token', { label: 'History' });

  const pageRef = await editorSDK.pages.add('token', {
    title: 'New Page',
    definition: getPageStructure(appDefinitionId),
    shouldAddMenuItem: false,
  });
  await setPagePermissions(pageRef, editorSDK);

  await addPageToComponent(editorSDK, appDefinitionId, pageRef);

  return openPagesPanel(editorSDK, pageRef);
}
export async function renamePage({ editorSDK, pageRef, title }) {
  await editorSDK.history.add('token', { label: 'History' });
  return editorSDK.pages.rename('token', { title, pageRef });
}

export async function changePageUrl({ editorSDK, pageRef, newPageUri }) {
  await editorSDK.history.add('token', { label: 'History' });
  saveCustomUrlToController(editorSDK, newPageUri);
  return editorSDK.pages.data.update('token', {
    pageRef,
    data: { pageUriSEO: newPageUri },
  });
}

async function getAdminPagesController(editorSDK) {
  const controllerRefs = await editorSDK.controllers.listControllers();
  return controllerRefs.find(
    async item =>
      (await editorSDK.controllers.getData('token', item)).type ===
      'singleAdminPageController',
  );
}

async function saveCustomUrlToController(editorSdk, customUrl) {
  const controllerRef = await getAdminPagesController(editorSdk);
  const currentData = await editorSdk.controllers.getData(
    'token',
    controllerRef,
  );
  editorSdk.controllers.saveConfiguration('token', {
    ...controllerRef,
    config: { ...currentData.config, customUrl },
  });
}

export async function getIsHiddenFromController(editorSdk): Promise<boolean> {
  const controllerRef = await getAdminPagesController(editorSdk);
  const currentData = await editorSdk.controllers.getData(
    'token',
    controllerRef,
  );
  return !!currentData.config.isHiddenInDashboard;
}
export async function saveIsHiddenToController(editorSdk, isHiddenInDashboard) {
  const controllerRef = await getAdminPagesController(editorSdk);

  const currentData = await editorSdk.controllers.getData(
    'token',
    controllerRef,
  );
  editorSdk.controllers.saveConfiguration('token', {
    ...controllerRef,
    config: { ...currentData.config, isHiddenInDashboard },
  });
}
